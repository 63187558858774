
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      colors: [
        { color: 'gold', active: true },
        { color: 'skin', active: false },
        { color: 'clouds', active: false },
        { color: 'bronze', active: false },
        { color: 'copper', active: false },
        { color: 'stone', active: false },
        { color: 'stone-light', active: false },
        { color: 'gentian', active: false },
        { color: 'grass', active: false },
      ],

      materialTextures: [
        { src: require('~/assets/configurator/texture_nuss.png') },
        { src: require('~/assets/configurator/texture_mais.png') },
        { src: require('~/assets/configurator/texture_kork.png') },
      ],

      hideTextures: false,

      sliders: [
        { value: 0, material: 'Kork' },
        { value: 0, material: 'Nuss' },
        { value: 0, material: 'Mais' },
      ],

      railStyle: {
        backgroundColor: '#969696',
      },
    }
  },

  computed: {
    // returns the active color
    activeColor() {
      return this.colors.find((color) => color.active === true)
    },

    groundColorImage() {
      const fileName = this.activeColor.color
      return require(`~/assets/configurator/color_${fileName}.png`)
    },
  },

  methods: {
    // return boolean if the sum of all sliders is over 100
    sliderSumOverMaximum() {
      return (
        this.sliders[0].value + this.sliders[1].value + this.sliders[2].value >
        100
      )
    },

    // calculate the values of the three sliders to have a maximum sum of 100
    sliderChange(index) {
      if (!this.sliderSumOverMaximum()) {
        return
      }
      switch (index) {
        case 0:
          while (this.sliderSumOverMaximum()) {
            if (this.sliders[1].value > this.sliders[2].value) {
              this.sliders[1].value -= 1
            } else if (this.sliders[1].value < this.sliders[2].value) {
              this.sliders[2].value -= 1
            } else {
              this.sliders[2].value -= 1
            }
          }
          break
        case 1:
          while (this.sliderSumOverMaximum()) {
            if (this.sliders[0].value > this.sliders[2].value) {
              this.sliders[0].value -= 1
            } else if (this.sliders[0].value < this.sliders[2].value) {
              this.sliders[2].value -= 1
            } else {
              this.sliders[0].value -= 1
            }
          }
          break
        case 2:
          while (this.sliderSumOverMaximum()) {
            if (this.sliders[0].value > this.sliders[1].value) {
              this.sliders[0].value -= 1
            } else if (this.sliders[0].value < this.sliders[1].value) {
              this.sliders[1].value -= 1
            } else {
              this.sliders[0].value -= 1
            }
          }
          break
        default:
          break
      }
    },

    setColor(index) {
      const colors = this.colors
      for (let i = 0; i < colors.length; i++) {
        if (i === index) {
          colors[i].active = true
        } else {
          colors[i].active = false
        }
      }
      this.hideMaterialTextures()
    },

    hideMaterialTextures() {
      this.hideTextures = true
      setTimeout(() => {
        this.hideTextures = false
      }, 1000)
    },

    bgColor(color) {
      switch (color) {
        case 'gold':
          return { bg: 'bg-ground-gold', border: 'border-ground-gold' }
        case 'skin':
          return { bg: 'bg-ground-skin', border: 'border-ground-skin' }
        case 'clouds':
          return { bg: 'bg-ground-clouds', border: 'border-ground-clouds' }
        case 'bronze':
          return { bg: 'bg-ground-bronze', border: 'border-ground-bronze' }
        case 'copper':
          return { bg: 'bg-ground-copper', border: 'border-ground-copper' }
        case 'stone':
          return { bg: 'bg-ground-stone', border: 'border-ground-stone' }
        case 'stone-light':
          return {
            bg: 'bg-ground-stone-light',
            border: 'border-ground-stone-light',
          }
        case 'gentian':
          return { bg: 'bg-ground-gentian', border: 'border-ground-gentian' }
        case 'grass':
          return { bg: 'bg-ground-grass', border: 'border-ground-grass' }
        default:
          return { bg: '', border: '' }
      }
    },
  },
}
